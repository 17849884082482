<template>
  <div>
    <div class="detail_item lineHeight36">
      <label>提交人</label>
      <!--  如果是未提审的数据 提交人就是当前用户      -->
      <span>{{form.status===0?userInfo.name:(form&&form.activityModel?form.activityModel.activitiNodeDetails[0].userList[0].name:'')}}</span>
      <label>提交时间</label>
      <span>{{form.submitTime}}</span>
    </div>
    <div class="detail_item lineHeight36">
      <label>店铺</label>
      <span>{{form.shopName||''}}</span>
      <label>店铺号</label>
      <span>{{form.shopNo||''}}</span>
    </div>
    <div class="detail_item lineHeight36">
      <label>业态</label>
      <span>{{form.businessName||''}}</span>
    </div>
    <div class="detail-status status" :class=[$reconava.getStatusClass(form.status)]>
           <span class="status-name">
          {{$reconava.getStatusName(form.status)}}</span>
    </div>
    <div class="detail_item remark clearfix">
      <label class="float-left">备注</label>
      <div class="detail_item__remark" :title="form.description">{{form.description}}</div>
    </div>
    <div class="detail_item ">
      <label class="float-left">附件</label>
      <attachments class="enclosure" :fileList="form.resources"></attachments>
    </div>
  </div>
</template>

<script>
import Attachments from "./attachments";
export default {
  name: "detail-form-info",
  components: {Attachments},
  data(){
    return {

    }
  },
  props:['form']
}
</script>

<style scoped lang="scss">
@import "../css/detail-form";

</style>