<template>
  <div>
    <div class="checkDetail">
      <div class="form-content">
        <el-tabs type="card" class="" v-model="activeTab">
          <el-tab-pane label="当前流程" name="current">
            <div class="current-record" >
              <div class="detail-block" v-if="form.id">
                <detail-form-info :form="form"></detail-form-info>
                <el-divider class="line-dividing"><span>流程图</span></el-divider>
                <detail-flow :flows="activityNodeList" :refuse-resource="form.activityModel.refuseResource||[]" :data-status="form.status"></detail-flow>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="历史记录" name="history"  v-if="showHistory&&!(origin==='copyToMe'||origin==='approval')">
            <div class="history-record">
              <template v-if="historyRecord&&historyRecord.length">
                <div class="record-item" v-for="record in historyRecord">
                  <detail-form-info :form="record"></detail-form-info>
                  <el-divider class="line-dividing"><span>流程图</span></el-divider>
                  <detail-flow :flows="[record.activityModel.activitiNodeDetails]" :refuse-resource="record.activityModel.refuseResource||[]" :data-status="record.status"></detail-flow>
                </div>
              </template>
              <template v-else>
                <div class="no-record-data">暂无数据</div>
              </template>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="occupy-ele"></div>
      <div class="detail_btn">
        <el-row>
          <el-button @click="cancel">返回</el-button>
          <template v-if="form.id">
            <el-button v-if="viewType === 'check'" @click="reject" type="danger">驳回</el-button>
            <el-button v-if="viewType === 'check'" @click="pass" type="primary">通过</el-button>
          </template>
        </el-row>
      </div>
    </div>


    <!--  通过弹窗  -->
    <el-dialog title="通过" :visible.sync="showPassModal" width="560px">
      <el-form :model="passForm" class="passApplyForm">
        <el-form-item label="审核意见" prop="reason">
          <el-input type="textarea" rows="3" class="" v-model="passForm.reason" maxlength="80" class="form-group_input"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="showPassModal = false">取 消</el-button>
          <el-button type="primary" @click="passSubmit">确 定</el-button>
        </span>
    </el-dialog>

    <!--  驳回弹窗  -->
    <el-dialog
        title="驳回"
        :visible.sync="dialogVisible"
        width="560px">
      <el-form :model="form_reject" ref="rejectForm" class="rejectApplyModal">
        <el-form-item label="附件">
          <el-upload
              ref="rejectUpload"
              class="upload-demo"
              action="/business/upload"
              accept=".png,.jpg,.jpeg,.pdf,.dwg,.zip,.rar"
              :show-file-list="false"
              :http-request="customUpload"
              :before-upload="beforeUpload"
              :limit="limitFileNum"
              :on-exceed="overFile"
              :multiple="true">
            <img v-if="imageUrl" :src="imageUrl" class="avatar">
            <el-button size="small" type="primary">点击上传</el-button>
            <div class="el-upload__tip fontBold" slot="tip">请上传jpg, png, pdf,zip,rar文件，且不超过100M,最多上传5个文件<br><span class="colorRed">注意：上传cad文件时，请附带上传pdf文件</span></div>
          </el-upload>
          <attachments class="enclosure" :fileList.sync="refuseResource" :canDelete="true"></attachments>
        </el-form-item>
        <el-form-item label="理由"
                      prop="reason"
                      :rules="[
                      { required: true, message: '请填写理由', trigger: ['blur', 'change'] },
                    ]">
          <el-input :maxlength="80" autofocus="on" type="textarea" :rows="3" v-model="form_reject.reason" placeholder="请填写理由" class="form-group_input"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="refuseSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>

</template>

<script>
import {processUpload} from "../../../../services/check";
  import Attachments from "./attachments";
  import uploadCommon from "../../../../utils/upload"
import {Loading} from "element-ui";
import {isIE} from "../../../../utils/utils";
import DetailFormInfo from "./detail-form-info";
import DetailFlow from "../../../../components/detail-flow";
  export default {
    name: "checkDetail",
    components: {DetailFlow, DetailFormInfo, Attachments},
    props: {
      from:{
        type:String,  // --todo--我的待办  --sponsor--我的发起  --approval--我的审批  --copy--我的抄送
        default(){   // 默认是从申请列表进来的
          return ''
        }
      },
      id: {
        type: String | Number,
        default() {
          return ""
        }
      },
      taskId: {
        type: String | Number,
        default() {
          return ""
        }
      },
      //check 审核  see查看
      type: {   // 从我的待办进来这里,type未check,从其余地方进来这里，type都是see
        type: String,
        default() {
          return "check"
        }
      },
      showHistory: {
        type:Boolean,
        default(){
          return true
        }
      },
    },
    data() {
      return {
        //签署审批内容
        origin:this.from,
        viewType:this.type,
        lastDetail: {},
        imageUrl: "",
        form_reject: {
          reason: null,
          file: null
        },
        showPassModal:false,
        dialogVisible: false,
        form: {
          resources: [],
          shopName: "",
          businessName: "",
          description: "",
          submitTime: ""
        },
        refuseResource:[], // 驳回上传的附件
        rejectFormRules:{
          reason:[{required: true, message: '请输入理由', trigger: 'change'}]
        },
        passForm:{},
        loadingOption: {
          lock:true,
          text:'文件上传中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        },
        activityNodeList:[],
        historyRecord:[],
        userInfo:null,
        activeTab:'current',
        uploadFileNum:0
      }
    },
    created() {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    },
    computed:{
      limitFileNum(){
        return 5-this.refuseResource.length
      }
    },
    methods: {
      async customUpload(data) {
        const file = data.file;
        // 如果是cad和pdf文件不需要压缩，直接上传
        //上传给后端，拿到url
        let form = new FormData;
        const type = file.type.split('/')[1]
        let ie = isIE();
        if(['png', 'jpg','jpeg'].includes(type)&&!ie){
          const img = await uploadCommon.readImg(file);
          const blob = await uploadCommon.compressImg(img,500, 500,file.type,);
          form.append("file", new window.File([blob], file.name, {type: file.type}));
        }else{
          form.append("file",file)
        }
        form.append("biz", this.form.typeId);
        let loading = Loading.service(this.loadingOption)
        this.uploadFileNum++;
        processUpload(form).then(res => {
          this.uploadFileNum--;
          if(this.uploadFileNum==0){
            loading.close();
          }
          if(this.refuseResource.length<5){
            this.refuseResource.push({
              name: res.data.fileName,
              url: res.data.fileUrl,
              size: res.data.fileSize,
              id: res.data.id,
              isPicturePreview:res.data.isPicturePreview
            })
          }else{
            this.refuseResource.splice(-1,1,{
              name: res.data.fileName,
              url: res.data.fileUrl,
              size: res.data.fileSize,
              id: res.data.id,
              isPicturePreview:res.data.isPicturePreview
            })
          }
          this.$refs.rejectUpload.clearFiles()
        })
      },

      overFile(files,fileList){
        if(files.length<=(5-this.refuseResource.length)){
          return true
        }else{
          this.$message.warning(`最多可上传5个附件,当前最多可选择 ${5-this.refuseResource.length} 个附件`);
          this.$refs.rejectUpload.clearFiles()
          return false
        }
      },

      getHistoryActivity(id){
        this.$api.check.getHisRecord({id:id}).then(response=>{
          if(response.code===200){
            this.historyRecord = response.data.list;
          }
        })
      },

      beforeUpload(file) {
        if(this.refuseResource.length<5){
          //验证文件格式和大小最大100M
          return uploadCommon.checkFileAndSize(file,100);
        }else{
          this.$message.error("最多上传5个文件");
          return false;
        }

      },

      // 返回
      cancel() {
        this.$emit("cancel",true)
      },

      // 打开驳回弹窗，填写驳回理由和附件
      reject() {
        this.form_reject={
          reason:"",
        };
        this.dialogVisible = true;
      },

      // 驳回提交
      refuseSubmit(){
        const _this = this;
        this.$refs['rejectForm'].validate((valid)=>{
          if(valid){
            let param = {
              id:_this.form.id,
              reason:_this.form_reject.reason,
              resources:_this.refuseResource,
              taskId:_this.taskId,
              typeId:this.form.typeId
            }
            this.$api.checkapi.rejectMyAppply(param).then(res=>{
              if(res.code===200){
                _this.$message.success("申请已驳回");
                _this.origin='approval'
                _this.viewType="approval"
                _this.dialogVisible = false;
                _this.getDetail()
              }else{
                _this.$message.error(res.msg);
              }
            })
          }
        })
      },

      // 通过
      pass(){
        this.passForm={
          reason:"",
        };
        this.showPassModal = true;
      },

      // 通过提交
      passSubmit() {
        let param = {
          id:this.form.id,
          taskId:this.form.activityModel.taskId,
          reason:this.passForm.reason,
          typeId:this.form.typeId
        }
        this.$api.checkapi.passMyAppply(param).then(res=>{
          if(res.code===200){
            this.$message.success("申请已通过");
            this.origin='approval'
            this.viewType="approval"
            this.showPassModal = false;
            this.getDetail()
          }else{
            this.$message.error(res.msg);
          }
        })
      },
      getDetail() {
        console.log(this.origin)
        let handler = this.origin==='todo'?
            this.$api.checkapi.getTodoDetail({taskId:this.taskId}):
            this.$api.check.getDecDetail({id: this.id})
        handler.then(res => {
          this.form = res.data;
          this.activityNodeList =[res.data.activityModel.activitiNodeDetails];
          if(!(this.origin==='copyToMe'||this.origin==='approval') && res.data.hasHistory) {
            this.getHistoryActivity(res.data.id)
          }
        })
      },
    },
    mounted() {
      this.getDetail()
    }
  }
</script>

<style lang="scss" scoped>
@import "../css/detail-form";
  .checkDetail{
    .line-dividing{
      margin: 20px 40px 20px 20px;
      width: 920px;
    }
    .form-content {
      background: #fff;
      border-radius: 6px;
      padding: 30px 20px 10px 20px;

      .history-record{
        min-height:590px;
        .record-item{
          width: 1000px;
          border: 1px solid #e3e3e3;
          border-radius: 10px;
          padding: 15px 0 0 0;
          margin-bottom: 10px;
          position: relative;
        }
      }
      .current-record{
        min-height:590px;
      }
      .detail-block{
        width: 1000px;
        position: relative;
      }
    }
    .occupy-ele{
      height: 60px;
      margin-bottom: 20px;
    }
    .detail_btn {
      padding: 12px;
      background: #fff;
      border-top: 1px solid #e3e3e3;
      text-align: center;
      position: fixed;
      bottom: 0;
      left: 240px;
      right: 0;
    }
  }
  .rejectApplyModal .attachments {
    width: auto;
  }
  .form-group_input{
    display: inline-block;
    width: calc(100% - 120px);
  }
  .upload-demo{
    overflow: hidden;
  }
  .no-record-data{
    padding: 16px 20px 0 20px;
  }
  ::v-deep .rejectApplyModal .el-form-item__label,
  ::v-deep .passApplyForm .el-form-item__label{
    width: 90px;
  }
  ::v-deep .rejectApplyModal.el-upload-list{
    margin-left: 80px;
  }
  ::v-deep.el-button--mini,.el-button--small{
    padding: 10px 18px;
    font-size: 14px;
  }
  ::v-deep .rejectApplyModal .el-form-item__error,
  ::v-deep .passApplyForm .el-form-item__error{
    margin-left: 100px;
    padding-top: 10px;
  }
  ::v-deep .el-upload__tip{
    float: right;
    margin-top: 0 !important;
    line-height: 1.5;
    max-width: 320px;
  }

</style>
