<template>
  <div class="searchBox">
    <div class="searchForm">
      <div class="searchItem">
        <span>选择店铺：</span>
        <el-cascader
                :filterable="true"
                clearable
                :show-all-levels="false"
                v-model="search.shopId"
                :options="shopList"
                size="medium"
                :props="{ expandTrigger: 'hover',value:'id',label:'name',children:'childList' }"></el-cascader>
        <span>状态：</span>
        <el-select
                clearable
                v-model="search.status"
                placeholder="请选择"
                size="medium"
        >
          <el-option label="全部" value=""></el-option>
          <el-option
                  v-for="item in $map.getter('auditStatus')"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="searchItem">
        <span>时间范围：</span>
        <el-date-picker
                :default-time="['00:00:00','23:59:59']"
                size="medium"
                v-model="date"
                :picker-options="pickerOptions"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
        >
        </el-date-picker>
      </div>
      <div class="searchItem">
        <el-button
                @click="getAppSummaryList()"
                type="primary"
                style="margin-left: 16px"
                size="medium"
        >查询
        </el-button
        >
        <el-button @click="_export()" style="margin-left: 16px" type="primary" size="medium">导出</el-button>
        <el-button
                @click="reset()"
                style="margin-left: 16px"
                size="medium"
        >重置
        </el-button
        >


      </div>
    </div>
  </div>
</template>

<script>
  import {getFloorShop} from "../../../services/check";

  export default {
    name: "searchBox",
    data() {
      return {
        date: [],
        shopList: [],
        search: {
          shopId: [],
          status: "",
          submitStartTime: "",
          submitEndTime: ""
        },
        //设置时间可选范围
        pickerOptions: {
          disabledDate(date){
            return date > new Date(new Date().setHours(23,59,59)).getTime()
          }
        }
      }
    },
    methods: {
      // 获取审核状态
      getAuditStatus(){
        this.statusArr = this.$map.getter('auditStatus');
        this.statusArr.unshift({value:'',label:'全部'})
      },
      getSearch(){
        let search = Object.assign({},this.search);
        if(search.shopId.length > 0){
          search.shopId = search.shopId[search.shopId.length - 1];
        }
        if(this.date && this.date.length > 0){
          search.submitStartTime = this.date[0];
          search.submitEndTime = this.date[1];
        }
        return search
      },
      getAppSummaryList() {
        this.$emit("change",this.form)
      },
      reset(){
        this.search =  {
          shopId: [],
          status: "",
          submitStartTime: "",
          submitEndTime: ""
        };
        this.date= [];
        this.getAppSummaryList()
      },
      // 导出不分页
      _export(){
        this.$emit('export')
      }
    },
    mounted() {
      //店铺搜索框
      getFloorShop().then(res => {
        this.shopList = res.data.childList
      })
    }
  }
</script>

<style scoped lang="scss">
.searchBox {

}
</style>
